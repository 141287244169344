import React, {useState} from 'react'
import './resume.css'
import { Header, Switch } from "../../components/index";
import ux_resume_pdf from "../../assets/Natalie Sesodia Resume.pdf"
import ux_resume_png from "../../assets/Natalie Sesodia Resume.png"
import se_resume_pdf from "../../assets/Natalie Sesodia Resume SE.pdf"
import se_resume_png from "../../assets/NSresumeSE.png"

const Resume = () => {
    const [resume, setResume] = React.useState(false);


    const DisplayResume = () => {
        let version_png = se_resume_png;
        let version_pdf = se_resume_pdf;

        if (!resume) {
            version_png = ux_resume_png;
            version_pdf = ux_resume_pdf
        }
        return (
            <>
            <img src={version_png} alt={"resume"}/>
            <a href={version_pdf} target={"_blank"} rel="noreferrer" download={"Natalie_Sesodia_Resume.pdf"}>
                <button>
                    <h6>Download PDF</h6>
                </button>
            </a>
            </>
        )
    }

    return (
        <div className={"content-container"} id={"resume-container"}>
            <Header pageTitle={"Resume"}/>
            {/*<Switch*/}
            {/*    onColor="#fff"*/}
            {/*    isOn={resume}*/}
            {/*    handleToggle={() => setResume(!resume)}*/}
            {/*/>*/}
            <DisplayResume/>
        </div>
    )
}

export default Resume