import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css'
import logo from '../../assets/ns_logo.png'

const Menu = () => (
    <>
        <h6 id={"home"}><a href={"/"}>Work</a></h6>
        <h6 id={"about"}><a href={"/about"}>About</a></h6>
        <h6 id={"resume"}><a href={"/resume"}>Resume</a></h6>
        <h6 id={"contact"}><a href={"/contact"}>Contact</a></h6>
    </>
)

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <div className={"_navbar"}>
            {/* Desktop Navbar */}

                {/* Left-hand Side */}
                <div className={"_navbar-links"} style={{justifyContent: "flex-start"}}>
                    <a className="_navbar-left" href={"/"} style={{display: "flex", flexDirection: "row"}}>
                        <img src={logo} alt={"logo"}/>
                        <h3>Natalie Sesodia</h3>
                    </a>
                </div>

                {/* Right-hand Side */}
                <div className={"_navbar-links"}>
                    <div className="_navbar-links_container">
                        <Menu/>
                    </div>
                </div>

            {/* Mobile NavMenu */}
            <div className={"_navbar-menu"}>
                { toggleMenu
                    ? <RiCloseLine color="#000" size="27" onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color="#000" size="27" onClick={() => setToggleMenu(true)} />
                }
                { toggleMenu && (
                    <div className={`_navbar-menu_container ${toggleMenu ? 'slide-left' : 'slide-right'}`}>
                        <Menu/>
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default Navbar