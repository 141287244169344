import React from 'react'
import './about.css'
import Header from "../../components/header/Header";
import headshot from "../../assets/headshot24.jpeg";
import car from "../../assets/f1-car.jpeg";
import lille from "../../assets/lille.jpeg";


let education = ["Bachelor of Science", "Digital Arts and Sciences Engineering", "University of Florida - May 2024"];
let skills = ["UX Design", "UI Design", "Wireframes", "Prototypes", "Attention to Detail", "Creative Problem Solving", "Analytical Thinking",]
let softwares = ["Figma", "Adobe Photoshop", "Adobe XD", "HTML", "CSS", "React.js", "Java", "Javascript", "Miro"]

const About = () => {

    let Tile = ({text}) => (
        <p id={"tile"}>{text}</p>
    )

    let TilesLoop = ({list, tile_bool}) => {
        if (tile_bool) {
            return (
                list.map((text, index) => (
                    <Tile key={index} text={text}/>
                ))
            )
        }
        else {
            return (
                list.map((text, index) => (
                    <p key={index}>{text}</p>
                ))
            )
        }
    }

    return (
        <div className={"content-container"} id={"about-container"}>
            <Header pageTitle={"About Me"}/>
            <div className={"content"}>
                <div id={"headshot-container"}>
                    <img src={headshot} alt={"headshot"} id={"headshot"}/>
                </div>
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <div className={"content-sub-sub-block"}>
                                <p>Exploring the captivating world where technology meets creativity, I find my passion in creating user-centered experiences that seamlessly blend innovation and human interaction.
                                    Having graduated from the University of Florida studying digital arts and computer science with a focus in user experience design, I now look to follow this passion into a career in product design.
                                </p>
                                <p>I first discovered my artistic and analytical nature through ballet. From my first plié at the barre to my final bow on stage, I loved the combination of both technicality and artistry. I feel the same excitement towards user experience design and development.</p>
                                {/*<p>Through personal projects, I've gained valuable hands-on experience across the entire design process – from research and ideation to pixel-perfect execution. I've had the opportunity to design apps and develop websites, continuously improving my skills along the way. However, I'm still eager to learn more and further refine my understanding of UI/UX design and development.</p>*/}
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <div className={"about-lists"}>
                                <div className={"about-lists-sections"}>
                                    <h3>Academics</h3>
                                    <TilesLoop list={education}/>
                                </div>

                                <div className={"about-lists-sections"}>
                                    <h3>Skills</h3>
                                    <div className={"tiles-row"}>
                                        <TilesLoop list={skills} tile_bool={true}/>
                                    </div>
                                </div>

                                <div className={"about-lists-sections"}>
                                    <h3>Softwares</h3>
                                    <div className={"tiles-row"}>
                                        <TilesLoop list={softwares} tile_bool={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* button fault work around */}
                <div className={"center-button"}>
                    <a href={"/resume"}>
                        <button to={"/resume"}>
                            <h6>Check out my resume</h6>
                            <img alt={"arrow-right-icon"}/>
                        </button>
                    </a>
                </div>

                {/* emoji sections */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <h3>In my free time you can find me...</h3>
                        <div className={"content-sub-block"}>
                            <div className={"about-lists"} id={"emoji-sections"}>
                                <div className={"about-lists-sections"} id={"emoji-section"}>
                                    <p id={"emoji"}><span role="img" aria-label="woman doing yoga emoji">🧘‍♀️</span></p>
                                    <h6>practicing yoga</h6>
                                </div>
                                <div className={"about-lists-sections"} id={"emoji-section"}>
                                    <p id={"emoji"}><span role="img" aria-label="ballet shoes emoji">🩰️</span></p>
                                    <h6>on the dance floor</h6>
                                </div>
                                <div className={"about-lists-sections"} id={"emoji-section"}>
                                    <p id={"emoji"}><span role="img" aria-label="camera emoji">📸️</span></p>
                                    <h6>capturing the moment <span style={{fontStyle: "italic"}}>(mostly my sleeping cat)</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* drawings */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <h3>Oh, and I dabble in drawing too!</h3>
                        <div className={"content-sub-block"}>
                            <div className={"multi-column-row"} id={"about-artwork-row"}>
                                <div className={"multi-column-column"} id={"about-artwork"}>
                                    <img src={car} alt={"image of sketch drawing"} className={"about-art-image"}/>
                                    <h6>F1 car of Fernando Alonso <span style={{fontStyle: "italic"}}>(2023)</span></h6>
                                </div>
                                <div className={"multi-column-column"} id={"about-artwork"}>
                                    <img src={lille} alt={"image of sketch drawing"} className={"about-art-image"}/>
                                    <h6>Downtown Lille, France <span style={{fontStyle: "italic"}}>(2022)</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* button fault work around */}
                <div className={"center-button"}>
                    <a href={"/solar-gators"}>
                        {/*{"/neuro-talent"}*/}
                        <button>
                            <h6>See my latest project</h6>
                            <img alt={"arrow-right-icon"}/>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default About