import React from 'react'
import './highlights.css'

const Highlights = ({problem, goals, actions, outcomes}) => {

    let TextLoop = ({list, list_bool}) => {
        if (list_bool) {
            return (
                list.map((text, index) => (
                    <ul><li><p key={index}>{text}</p></li></ul>
                ))
            )
        }
        else {
            return (
                list.map((text, index) => (
                    <p key={index}>{text}</p>
                ))
            )
        }
    }

    let Row = ({heading, blurbs, list_bool}) => {
        return (
            <div className={"highlight-row"}>
                <h5>{heading}</h5>
                <div className={"text-column"}>
                    <TextLoop list={blurbs} list_bool={list_bool}/>
                </div>
            </div>
        )
    }

    return (
        <div id={"highlight-container"}>
            <h3>Highlights</h3>
            <div className={"highlight-rows-container"}>
                <Row heading={"Problems"} blurbs={problem}/>
                <Row heading={"Goals"} blurbs={goals}/>
                <Row heading={"Actions"} blurbs={actions} list_bool={true}/>
                <Row heading={"Outcomes"} blurbs={outcomes} list_bool={true}/>
            </div>
        </div>
    )
}

export default Highlights