import React from 'react'
import './footer.css'
import linkedin from '../../assets/icon-linkedin.png'

const Footer = () => {
    return (
        <div id={"footer-container"}>
            <h6><a href={"/contact"}>Contact Me</a></h6>
            <a href={"https://linkedin.com/in/natalie-sesodia"} target="_blank" rel="noreferrer"><img src={linkedin} alt={"LinkedIn"} id={"linkedin-icon"}/></a>
            <p id={"copyright"}>© 2024 Natalie Sesodia</p>
        </div>
    )
}

export default Footer