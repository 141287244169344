import React from 'react'
import './header.css'
import emoji from '../../assets/happy-smile.webp'

const Header = ({pageTitle, home}) => {
    if (home) {
        return (
            <div className={"_header"} id={"home"}>
                <h3>Hi, I'm Natalie <span role="img" aria-label="smiley emoji">😊</span></h3>
                <h1>{pageTitle}</h1>
                <h6>Problem-solving with a holistic approach, collaborative mindset, and great attention to detail</h6>
            </div>
        )
    }
    else {
        return (
            <div className={"_header"}>
                <h1>{pageTitle}</h1>
            </div>
        )
    }
}

export default Header