import React from 'react';
import './problemframing.css'
import quotation from '../../assets/quotes.png';
import {hf_home, hf_journal} from "../../assets/project_pngs/neurotalent";

const ProblemFraming = ({project}) => {
    let ArrowBox = ({begin, emphasize, end, source}) => {
        return (
            <div className={"arrow-box"}>
                <div className={"arrow-box-content"}>
                    <p>{begin}<span className={"span-accent"}>{emphasize}</span>{end}
                        <span id={"source"}>{source}</span></p>
                </div>
            </div>
        )
    }

    let Quotes = ({q1, q2, q3, q4}) => {
        //q1 = [q_begin, emphasis, q_end]
        //q1 = [q_begin, emphasis1, q_middle, emphasis2, q_end]
        let QuoteBox = ({quote}) => {
            if (quote.length === 3) {
                return (
                    <div className={"quote-box"}>
                        <p>{quote[0]}
                            <span className={"span-accent-2"}> {quote[1]} </span>
                            {quote[2]}
                        </p>
                    </div>
                )
            }
            else {
                return (
                    <div className={"quote-box"}>
                        <p>{quote[0]}
                            <span className={"span-accent-2"}> {quote[1]} </span>
                            {quote[2]}
                            <span className={"span-accent-2"}> {quote[3]} </span>
                            {quote[4]}
                        </p>
                    </div>
                )
            }
        }

        return (
            <>
                <div id={"quote-img-container"}>
                    <img src={quotation} alt={"quotation mark"}/>
                </div>
                <div className={"content-sub-sub-block"} id={"quotes-container"}>
                    {q1 === "" ? "" : <QuoteBox quote={q1}/>}
                    {q2 === "" ? "" : <QuoteBox quote={q2}/>}
                    {q3 === "" ? "" : <QuoteBox quote={q3}/>}
                    {q4 === "" ? "" : <QuoteBox quote={q4}/>}
                </div>
                <div id={"quote-author-container"}>
                    <p>- Anonymous ASD Adults</p>
                </div>
            </>
        )
    }

    let Feature = ({img, alt, heading, subtitle, text, mobile, flip}) => {
        let row_container_id = "mobile-solution";
        let img_container_id = "mobile-solution-img";
        if (!mobile) {
            row_container_id = "desktop-solution"
            img_container_id = "desktop-solution-img";
        }
        let Image = () => {
            return (
                <div className={"multi-column-column"} id={img_container_id}>
                    <img src={img} alt={alt} className={"rogue_img"}/>
                </div>
            )
        }

        let Text = () => (
            <div className={"multi-column-column"} id={"solution-content"}>
                <h2>{heading}</h2>
                <h6>{subtitle}</h6>
                <p>{text}</p>
            </div>
        )

        if (flip) {
            return (
                <div className={"multi-column-row"} id={row_container_id}>
                    <Image/>
                    <Text/>
                </div>
            )
        }
        else {
            return (
                <div className={"multi-column-row"} id={row_container_id}>
                    <Text/>
                    <Image/>
                </div>
            )
        }
    }

    //if project === "neurotalent"
    return (
        <div className={"content-section"}>
            <h4>Background</h4>
            <div className={"content-blocks"}>
                <div className={"content-sub-block"}>
                    <h5>Problem</h5>
                    <div className={"content-sub-sub-block"}>
                        <p>Adults with Autism Spectrum Disorder (ASD) face unique challenges in finding and maintaining jobs, often experiencing stress and anxiety due to unpredictability in their routine, social interactions, and sensory overload, often leading to burnout or meltdowns.</p>
                        <ArrowBox
                            begin={"While many ASD adults have a wide range of skills and talents, "}
                            emphasize={"over 85% are unemployed"}
                            end={" in the United States and Canada "}
                            source={"(goldenstepsaba.com)"}
                        />
                    </div>
                </div>
                <div className={"content-sub-block"}>
                    <h3>Why do ASD adults struggle with employment?</h3>
                    <div className={"content-sub-sub-block"}>
                        <p>For many ASD adults, finding and maintaining employment can be a significant challenge due to
                            <ul>
                                <li>Unpredictable <span className={"span-accent"}>task changes</span> that break their routine</li>
                                <li>Overwhelming <span className={"span-accent"}>sensory environments</span>, such as bright lights or loud noises</li>
                                <li>Uncomfortable <span className={"span-accent"}>social interactions</span> like talking to unknown people or customers all day</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className={"content-sub-block"}>
                    <Quotes
                        q1={["\"The human aspect of my work causes", "major anxiety and burnout", "\""]}
                        q2={["\"I still have", "meltdowns", "and get", "overwhelmed", "sometimes\""]}
                        q3={["\"I'm not capable of", "heavy workloads", "and I don't want to ", "socialize", "with coworkers\""]}
                        q4={["\"By the six month mark, the", "anxiety", "and", "dread", "starts to creep in again\""]}
                    />
                </div>
                <div className={"content-sub-block"}>
                    <ArrowBox
                        begin={"These challenges often lead to missed opportunities for both employers and talented ASD individuals"}
                        emphasize={""}
                        end={""}
                        source={""}
                    />
                </div>
                <div className={"content-sub-block"}>
                    <h3>How can we accommodate the unique needs of ASD adults?</h3>
                    <h5>Solution</h5>
                    <p>NeuroTalent, a mobile app to simplify the job search for ASD adults by
                        <span className={"span-accent"}> matching their skills and personal strengths to positions that can accommodate for their sensory needs</span>
                        . Additionally, the app provides resources for professional development and workplace wellness to improve long-term job satisfaction.</p>
                </div>
                <div className={"content-sub-block"}>
                    {/*<div className={"multi-column-column"}>*/}
                    {/*    <img src={hf_journal} alt={"hi fidelity home wireframe"} className={"rogue_img"}/>*/}
                    {/*</div>*/}
                    <Feature img={hf_home} alt={"hi fidelity home wireframe"}
                             heading={"Tailored Recommendations"}
                             subtitle={"A streamlined job board that prioritizes clarity and customization"}
                             text={"Forget endless scrolling! Our home page spotlights your ideal jobs with clear summaries (role, company, location, etc.) and a personalized match score. Plus, flexible filters let you search by sensory needs, work environment, or desired skills, landing you jobs that truly click."}
                             mobile={true}
                             flip={true}
                    />
                    <Feature img={hf_journal} alt={"hi fidelity journal wireframe"}
                             heading={"Reflective Journaling"}
                             subtitle={"A streamlined job board that prioritizes clarity and customization"}
                             text={"Use daily journal prompts to reflect on daily experiences, emotions, and well-being, helping identify work-related triggers, track progress, and improve long-term job satisfaction"}
                             mobile={true}
                             flip={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProblemFraming