import React from 'react'
import './otherprojects.css'
import {ProjectPreview} from "../index";
import {sg_preview} from "../../assets/project_pngs/solargators";
import {rts_preview} from "../../assets/project_pngs/rts";
import {myeo_preview} from "../../assets/project_pngs/myeo";

const OtherProjects = ({proj1, proj2}) => {

    const DisplayPreview = ({name}) => {
        if (name === "sg") {
            return (
                <ProjectPreview title={"Solar Gators Website"}
                                categories={["UX Research", "UI Design", "Web Development"]}
                                pic={sg_preview}
                                route={"./solar-gators"}
                                colors={["#FFAD73",  "rgba(255, 132, 45, .15)",  "#E86000"]}
                />
            )
        }
        else if (name === "rts") {
            return (
                <ProjectPreview title={"RTS Bus App"}
                                categories={["UI Design", "UX Design", "UX Research"]}
                                pic={rts_preview}
                                route={"./rts"}
                                colors={["#86B6FF", "rgba(134, 182, 255, .28)", "#005FF0"]}
                />

            )
        }
        else {
            return (
                <ProjectPreview title={"My Essential Oils App"}
                                categories={["UX Design"]}
                                pic={myeo_preview}
                                route={"./my-eo"}
                                colors={["#63d9b9",  "rgba(131, 225, 199, .28)", "#007A59"]}
                />
            )
        }
    }

    return (
        <div className={"op-container"}>
            <div className={"line-divider"}/>
            <h3>Other Projects</h3>
            <div className={"op-previews-container"}>
                <DisplayPreview name={proj1}/>
                <DisplayPreview name={proj2}/>
            </div>
        </div>
    )
}

export default OtherProjects