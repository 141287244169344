import React from "react";
import './tags.css'

const Tags = ({categories, colors}) => {
    let Tag = ({tag}) => {
        return (
            <div className={"tag"} style={{background: colors[1], color: colors[2]}}>
                <h5>{tag}</h5>
            </div>
        )
    }

    return (
        <div className={"tags-row"}>
            { categories[0] ? <Tag tag={categories[0]}/> : "" }
            { categories[1] ? <Tag tag={categories[1]}/> : "" }
            { categories[2] ? <Tag tag={categories[2]}/> : "" }
            { categories[3] ? <Tag tag={categories[3]}/> : "" }
        </div>
    )
}

export default Tags