import React from 'react'
import './pplandscape.css'
import { Tags } from '../index'

const PPLandscape = ({role, title, categories, description, pic, route, align, colors}) => {

    let Content = () => {
        return (
            <div className={"ppl-content-container"}>
                <div>
                    <h6>{role}</h6>
                    <a href={route}><h2>{title}</h2></a>
                </div>

                <Image id={"img_mobile"}/>

                <Tags categories={categories} colors={colors}/>
                <a href={route}>
                    <p>{description}</p>
                </a>

                <div id={"ppl-btn-container"}>
                    <a href={route} className={"a-button"}>
                        <button id={"ppl-button"}>
                            <h6>View Project</h6>
                            <img alt={"arrow-right-icon"}/>
                        </button>
                    </a>
                </div>

            </div>
        )
    }

    let Image = ({id}) => {
        return (
            <a href={route} id={id}>
                <div className={"ppl_img_container"} style={{background: colors[0]}}>
                    <img src={pic} alt={"ppl img"}/>
                </div>
            </a>
        )
    }

    if (align === "flip") {
        return (
            <div className={"ppl"} id={"flip"}>
                <Content/>
                <Image id={"img_landscape"}/>
            </div>
        )
    }
    else {
        return (
            <div className={"ppl"}>
                <Content/>
                <Image id={"img_landscape"}/>
            </div>
        )
    }
}

export default PPLandscape