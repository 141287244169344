import React from 'react'
import './neurotalent.css'
import {Header, Overview, Goals, OtherProjects, ProblemFraming, YouTubePlayer} from "../../../components/index";
import { site_map, thematic_analysis, card_sorting, design_req, linkedin, special, hireautism, mentra, vanderbilt, lowfi,
        lf_saved, lf_home, lf_applied,hf_saved, hf_home_filter, hf_journal, hf_notifications, hf_home, hf_applied, hf_profile,
        hf_how, prototype, preview
        } from "../../../assets/project_pngs/neurotalent/index";


let colors = ["#7B7EDB",  "rgba(128, 133, 255, .25)", "#3337B7"];

const NeuroTalent = () => {
    return (
        <div className={"content-container"}>
            <Header pageTitle={"NeuroTalent App"}/>
            <div className={"content"}>
                <Overview
                    summary={"A comprehensive mobile app that equips individuals with Autism Spectrum Disorder (ASD) to confidently search for, secure, and thrive in meaningful careers"}
                    categories={["UX Design", "UI Design"]}
                    colors={colors}
                    role={["UX Designer"]}
                    duration={["Jan - April 2024"]}
                    type={"Mobile App Design"}
                    team={"Team: 4 designers"}
                    tools={["Figma", "MAXQDA"]}
                    pic={""}
                />

                {/*Jump to Final Prototype Button*/}
                <div className={"center-button"} >
                    <a href={"/neuro-talent#prototype"} rel={"noreferrer"} >
                        <button>
                            <h6>Jump to Final Prototype</h6>
                            <img id={"arrow-down-icon"} alt={"arrow-down-icon"}/>
                        </button>
                    </a>
                </div>

                {/* Problem Framing */}
                <ProblemFraming project={"neurotalent"}/>

                {/*<img src={preview} alt={"preview mockup frames"} className={"rogue_img"}/>*/}

                {/* ----BACKGROUND---- */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Our Approach</h3>
                            <div className={"tri-section-container"} id={"approach-container"}>
                                <div className={"tri-section"} id={"approach"}>
                                    <h5>Research</h5>
                                    <p>Discussion with Subject Matter Experts</p>
                                    <p>Online Ethnography</p>
                                    <p>Affinity Mapping</p>
                                    <p>Competitive Analysis</p>
                                    <p>Focus Group Interview</p>
                                    <p>Personas</p>
                                </div>
                                <div className={"tri-section"} id={"approach"}>
                                    <h5>Design</h5>
                                    <p>Informed Brainstorming</p>
                                    <p>Wireframes</p>
                                    <p>User Feedback</p>
                                    <p>Hi-Fi Design</p>
                                    <p>Interactive Prototype</p>
                                </div>
                                <div className={"tri-section"} id={"approach"}>
                                    <h5>Testing</h5>
                                    <p>User Testing with ASD Individuals</p>
                                    <p>Design Iterations</p>
                                </div>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>My Contribution</h3>
                            <p>Throughout this project, my team and I worked collaboratively across research, design,
                                and user testing. I was responsible for helping establish
                                <span className={"span-accent"}> project scope</span>
                                ,
                                <span className={"span-accent"}> goals </span>
                                and
                                <span className={"span-accent"}> design requirements </span>
                                as well as designing
                                <span className={"span-accent"}> wireframes</span>
                                , and the final
                                <span className={"span-accent"}> prototype</span>.
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----RESEARCH---- */}
                <div className={"content-section"}>
                    <h4>Research</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Discussion with Subject Matter Experts</h3>
                            <p>Before diving into the project, we consulted with Dr. Ann-Marie Orlando and Dr. Nigel Newbutt, leading experts in ASD cognitive development. Their insights into the struggles, frustrations, and goals of ASD individuals lead to these key takeaways:
                                <ul>
                                    <li><span className={"span-accent"}>Diverse Challenges</span>: the wide range of challenges faced by ASD individuals vary significantly from person to person and between levels</li>
                                    <li><span className={"span-accent"}>Strengths</span>: many ASD individuals are detail-oriented and enjoy repeating tasks that require precision and correctness</li>
                                    <li><span className={"span-accent"}>Sensory Sensitivities</span>: ASD individuals have greater sensitivities to sensory and environmental factors, which are highly important to their well-being and workplace experience</li>
                                </ul>
                            </p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h2>Data Collection & Analysis</h2>
                            <h3>Online Ethnography</h3>
                            <p>We collected and analyzed 100+ online posts and comments from various ASD community forums across Facebook, Reddit, and LinkedIn. We focused on posts that discussed experiences with social interaction, sensory sensitivities, or employment challenges.</p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Thematic Analysis</h3>
                            <p>Using MAXQDA, we conducted a thematic analysis to identify recurring topics and patterns.</p>
                            <img className={"rogue_img"} src={thematic_analysis} alt={"thematic analysis"}/>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Competitor Analysis</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>We looked at 5 career focused platforms for ASD and non-ASD individuals.</p>
                                <div className={"row-space-between"} id={"nt-competitor-logos"}>
                                    <img className={"competitor-logo"} src={linkedin} alt={"linkedin logo"}/>
                                    <img className={"competitor-logo"} src={hireautism} alt={"hire autism logo"}/>
                                    <img className={"competitor-logo"} src={special} alt={"specialisterne logo"}/>
                                    <img className={"competitor-logo"} src={vanderbilt} alt={"vanderbuilt logo"}/>
                                    <img className={"competitor-logo"} src={mentra} alt={"mentra logo"}/>
                                </div>
                                <div id={"sg-competitor-stats"}>
                                    <div className={"multi-column-row"}>
                                        <div className={"multi-column-column"}>
                                            <h2>20%</h2>
                                            <ul>
                                                <li>Included company level of support information</li>
                                                <li>Provided details about sensory environment</li>
                                            </ul>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>40%</h2>
                                            <ul>
                                                <li>Included in depth information about company (size, industry, etc.)</li>
                                                <li>Provided skill matching in job description</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Findings</h3>
                            <p>Understanding the challenges faced by ASD individuals in the job market, our research revealed several unmet needs, such as
                                <ul>
                                    <li>Current resources having <span className={"span-accent"}>limited sensory environment information</span>, making it difficult for ASD adults to assess if a workplace will be suitable for them</li>
                                    <li><span className={"span-accent"}>Lack of transparency around employer's neurodiversity support</span>, creating uncertainty about accommodations and inclusion practices</li>
                                    <li>Existing resources focusing solely on job search and placement, <span className={"span-accent"}>neglecting the need for continued support after securing a position</span></li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----DEFINE---- */}
                <div className={"content-section"}>
                    <h4>Define</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Goals</h2>
                            <p>
                                Considering our research findings and the current gaps in ASD oriented career tools, we created goals for a comprehensive mobile app to help ASD individuals to confidently search for, secure, and thrive in meaningful careers.
                                <Goals
                                    g={[["Improve job search success", " by job matching based on sensory preferences and social interaction requirements via user-input preferences & customizable job filters"],
                                        ["Increase preparedness", " by offering professional development resources including interview preparation and resume generators"],
                                        ["Help maintain job satisfaction and well-being", " by offering a journaling tool and personalized insights to identify potential sensory triggers and avoid burnout"],
                                        ["Reduce potential for stress and sensory overload", " with a clean and uncluttered interface that prioritizes essential information by using clear typography, high-contrast colors, and ample white space"]]}
                                />
                            </p>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>Target Users</h3>
                        </div>
                    </div>
                </div>

                {/* ----IDEATE---- */}
                <div className={"content-section"}>
                    <h4>Ideate</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Design Requirements</h3>
                            <div className={"content-sub-sub-block"}>
                                <h5>Job Search Tools</h5>
                                <p>
                                    <ul>
                                        <li><span className={"span-accent"}>Job Matching</span>: Automatically refine job searches based on sensory preferences, work environment needs, and social interaction requirements</li>
                                        <li><span className={"span-accent"}>Accessibility Features</span>: Clear and consistent interface design, text-to-speech functionality, and compatibility with assistive technologies</li>
                                        <li><span className={"span-accent"}>ASD Catered Job Description</span>: Highlight day-to-day tasks, environment and sensory information, and social interaction requirements</li>
                                    </ul>
                                </p>
                                <h5>Professional Development Resources</h5>
                                <p>
                                    <ul>
                                        <li><span className={"span-accent"}>Interview Preparation</span>: Interview tutorial, social skills training, and interview tips specifically geared towards ASD individuals</li>
                                        <li><span className={"span-accent"}>Resume Generator</span>: Create resume from user input information with clear and concise language to showcase skills and experiences effectively</li>
                                    </ul>
                                </p>
                                <h5>Workplace Support</h5>
                                <p>
                                    <ul>
                                        <li><span className={"span-accent"}>Journaling</span>: Daily prompt to motivate self-reflection of emotions and help identifying meltdown triggers</li>
                                        <li><span className={"span-accent"}>Burnout Alert</span>: Press-and-hold button for ASD individuals to communicate with colleague/manager they are becoming overwhelmed or burnt-out without potentially stressful social interaction</li>
                                        <li><span className={"span-accent"}>Messaging</span>: Offers features to facilitate clear and concise communication with colleagues and supervisors</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Card Sorting & Site Map</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>After a card sorting exercise, we developed a site map that was further refined throughout the design process.</p>
                                <img src={site_map} alt={"style guide"} className={"rogue_img"}/>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Low-Fi Wireframe</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Based on the site map, we created our first iteration of low-fi wireframes. See complete
                                    <span className={"span-accent"}>
                                        <a href={"https://www.figma.com/file/8y5lpRqVpCZyeV9bTnPdj0/NeuroTalent?type=design&node-id=118%3A12&mode=design&t=E2hQnF64phDJIPYU-1"} target="_blank" rel="noreferrer"> Figma design</a>
                                    </span>.
                                </p>
                                <img src={lowfi} alt={"low fidelity wireframe"} className={"rogue_img"}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----DESIGN---- */}
                <div className={"content-section"}>
                    <h4>Design</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            {/*FIX: mobile sizing*/}
                            <h3>Design Decisions</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Here's a closer look at the design decisions I made for key features of the app including job matching, tracking and journaling.</p>
                                <h5>Home Page Job Matching</h5>

                                {/*FIX: Add tracking wireframe & updates job description frames*/}
                                <div className={"multi-column-row"} id={"design_decision"}>
                                    <div className={"multi-column-column"}>
                                        <p>
                                            <ul id={"extra_list_spacing"}>
                                                <li><span className={"span-accent"}>Clear Job Summaries</span> showing key details like role, company, location, job type, and % match</li>
                                                <li><span className={"span-accent"}>Flexible Filtering</span> defaults to match user profile, but allow for easy customization without altering profiles</li>
                                                <li><span className={"span-accent"}>Save for Later</span> function lets users save jobs for future reference and potentially apply later</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className={"multi-column-column"}>
                                        <img src={hf_home} alt={"hi fidelity home wireframe"} className={"rogue_img"}/>
                                    </div>
                                    <div className={"multi-column-column"}>
                                        <img src={hf_home_filter} alt={"hi fidelity home wireframe"} className={"rogue_img"}/>
                                    </div>
                                </div>

                                <h5>User Profile & Journaling</h5>
                                <div className={"multi-column-row"} id={"design_decision"}>
                                    <div className={"multi-column-column"}>
                                        <img src={hf_profile} alt={"hi fidelity home wireframe"} className={"rogue_img"}/>
                                    </div>
                                    <div className={"multi-column-column"}>
                                        <img src={hf_journal} alt={"hi fidelity home wireframe"} className={"rogue_img"}/>
                                    </div>
                                    <div className={"multi-column-column"}>
                                        <p>
                                            <ul id={"extra_list_spacing"}>
                                                <li><span className={"span-accent"}>Reflective Journaling</span> encourage users to reflect on daily experiences, emotions, and well-being, helping identify work-related triggers, track progress, and improve long-term job satisfaction</li>
                                                <li><span className={"span-accent"}>Daily Journal Prompts</span> help overcome writer's block and foster meaningful reflection</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"content-sub-block"} id={"prototype"}>
                            <h3>High-Fi Prototype</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>After incorporating valuable user feedback from ASD individuals, we brought together a high-fi prototype NeuroTalent. You can check out the
                                    <span className={"span-accent"}>
                                        <a href={"https://www.figma.com/proto/8y5lpRqVpCZyeV9bTnPdj0/NeuroTalent?page-id=20%3A2&type=design&node-id=420-2079&viewport=425%2C461%2C0.05&t=1ICLkM3vaUnH5v25-1&scaling=scale-down&starting-point-node-id=420%3A2079&show-proto-sidebar=1&mode=design"} target="_blank" rel="noreferrer"> full prototype on Figma</a>.
                                    </span>
                                </p>
                                <div className={"prototype_container"}>
                                    <video controls autoPlay loop muted className={"mobile_prototype"}>
                                        <source src={prototype} type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----TESTING---- */}
                <div className={"content-section"}>
                    <h4>Testing</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Feedback</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Feedback from a survey of 20+ Solar Gators members showed</p>
                                <div id={"sg-feedback"}>
                                    <div className={"multi-column-row"}>
                                        <div className={"multi-column-column"}>
                                            <ellipse id={"81"}/>
                                            <h2>81%</h2>
                                            <p>Felt satisfied with the amount of information on the website</p>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>75%</h2>
                                            <p>Of new members visited the website to learn about the club</p>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>33%</h2>
                                            <p>Of new members joined via the website</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----REFLECTION---- */}
                <div className={"content-section"}>
                    <h4>Reflection</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>What I Learnt</h3>
                            <p>This project underscored the importance of user research and understanding your target audience, especially when designing for heightened accessibility needs like those of ASD individuals. By delving into their challenges and preferences, we were able to make informed design decisions that prioritized user needs. This deep understanding led to a more thoughtful approach, ensuring each decision truly contributes to a positive and empowering experience for ASD users.</p>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>Future Improvements</h3>
                            <p>Given more time, we would expand the platform's functionality by integrating a dedicated wellness hub. This hub would offer greater resources and tools to support emotional well-being, fostering long-term job success for ASD users.</p>
                        </div>
                    </div>
                </div>
            </div>

            <OtherProjects proj1={"sg"} proj2={"rts"}/>
        </div>

    )
}

export default NeuroTalent