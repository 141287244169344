import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './contactform.css'

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const toastifySuccess = () => {
        toast('Form sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        });
    };

    const onSubmit = async (data) => {
        const { name, email, message } = data;
        try {
            const templateParams = {
                name,
                email,
                message
            };

            await emailjs.send(
                'service_njs14',
                'template_contact',
                templateParams,
                'umcjxH0szKs9iMfcF'
            );

            reset();
            toastifySuccess();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={"content-container"} id={"form-container"}>
            <form id={"contact-form"} onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className={"content-sub-sub-block"}>
                    <div className={"contact-form-row"}>
                        <input
                            type={'text'}
                            name={'name'}
                            {...register('name', {
                                required: { value: true, message: 'Please enter your name' },
                                maxLength: {
                                    value: 30,
                                    message: 'Please use 30 characters or less'
                                }
                            })}
                            className={'form-input'}
                            placeholder={'Name'}
                        />
                        {errors.name && <h6 className='errorMessage'>{errors.name.message}</h6>}
                    </div>
                    <div className={"contact-form-row"}>
                        <input
                            type={'text'}
                            name={'email'}
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                            className={'form-input'}
                            placeholder={'Email'}
                        />
                        {errors.email && (
                            <h6 className='errorMessage'>Please enter a valid email address</h6>
                        )}
                    </div>
                    <div className={"contact-form-row"}>
                        <textarea
                            type={'text'}
                            name={'message'}
                            {...register('message', {
                                required: true
                            })}
                            className={'form-input'}
                            id={'message'}
                            placeholder={'Hey Natalie,'}
                        />
                        {errors.message && <h6 className='errorMessage'>Please enter a message</h6>}
                    </div>
                </div>
                <div className={"center-button"}>
                    <button type={'submit'}>
                        <h6>Submit</h6>
                    </button>
                </div>


            </form>
            <ToastContainer/>
        </div>
    )
}

export default ContactForm