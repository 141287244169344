import React from 'react'
import './home.css'
import {Header, PPLandscape} from "../../components/index";
import {rts_preview} from "../../assets/project_pngs/rts";
import {sg_preview} from "../../assets/project_pngs/solargators/index";
import {myeo_preview} from "../../assets/project_pngs/myeo";
import {neuro_preview} from "../../assets/project_pngs/neurotalent/index";

// colors = [picture, tag, text]
let nt_colors = ["#7B7EDB",  "rgba(128, 133, 255, .25)", "#3337B7"];
let sg_colors = ["#FFAD73",  "rgba(255, 132, 45, .15)",  "#E86000"];
let rts_colors = ["#86B6FF", "rgba(134, 182, 255, .28)", "#005FF0"];
let eo_colors = ["#63d9b9",  "rgba(131, 225, 199, .28)", "#007A59"];

const Home = () => (
    <div id={"home-container"}>
        <Header pageTitle={"Product Designer & Developer"} home={true}/>
        {/*<h6 id={"home-summary"}>Crafting immersive digital experiences at the intersection of creativity and functionality.</h6>*/}

        <div className={"project-previews-container"}>
            {/*<PPLandscape*/}
            {/*    role={"UX Designer"}*/}
            {/*    title={"NeuroTalent App"}*/}
            {/*    categories={["UI Design", "UX Design"]}*/}
            {/*    description={"A comprehensive mobile app that equips individuals with Autism Spectrum Disorder (ASD) to confidently search for, secure, and thrive in meaningful careers"}*/}
            {/*    pic={neuro_preview}*/}
            {/*    route={"./neuro-talent"}*/}
            {/*    align={"flip"}*/}
            {/*    colors={nt_colors}*/}
            {/*/>*/}

            <PPLandscape
                role={"Product Designer & Developer"}
                title={"Solar Gators Website"}
                categories={["UX Research", "UI Design", "Web Development"]}
                description={"Increased interest and sponsorship by redesigning Solar Gator’s website to incorporate more information and streamline sponsorship with a modernized style guide"}
                pic={sg_preview}
                route={"./solar-gators"}
                align={"default"}
                colors={sg_colors}
            />

            <PPLandscape
                role={"UI/UX Designer"}
                title={"RTS Bus App"}
                categories={["UI Design", "UX Design", "UX Research"]}
                description={"Redesigned the Gainesville RTS bus app using Google Material Design conventions to " +
                    "improve the on-boarding experience for new app users " +
                    "and presentation of route information"}
                pic={rts_preview}
                route={"./rts"}
                align={"flip"}
                colors={rts_colors}
            />

            <PPLandscape
                role={"UX Designer"}
                title={"My Essential Oils iOS App"}
                categories={["UX Design"]}
                description={"Designed a recipe search filter feature for the MyEO Young Living Essential Oils mobile iOS app"}
                pic={myeo_preview}
                route={"./my-eo"}
                align={"default"}
                colors={eo_colors}
            />
        </div>
    </div>
)


export default Home