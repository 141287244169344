import React from 'react'
import './userscenario.css'

let Step = ({i, p}) => (
    <div className={"flex-row-container"}>
        <img src={i} alt="user scenario" className={"img-half"}/>
        <div className={"flex-column-container"}>
            <p>{p}</p>
        </div>
    </div>
)

const UserScenario = ({i0, i1, i2, i3, p0, p1, p2, p3}) => (
    <>
        <Step i={i0} p={p0}/>
        <Step i={i1} p={p1}/>
        <Step i={i2} p={p2}/>
        <Step i={i3} p={p3}/>
    </>
)

export default UserScenario