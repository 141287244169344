import React from 'react'
import './swot.css'

const Swot = ({s, w, o, t}) => {
    let Bullets = ({l}) => {
        return (
            <p><ul>
                { l[0] ? <li>{l[0]}</li> : "" }
                { l[1] ? <li>{l[1]}</li> : "" }
                { l[2] ? <li>{l[2]}</li> : "" }
                { l[3] ? <li>{l[3]}</li> : "" }
            </ul></p>
        )
    }

    return (
        <div className={"bg"}>
            {/*First Row*/}
            <div className={"s-row"}>
                <div className={"s-col"} id={"first-row"}>
                    <h6>Strengths</h6>
                    <Bullets l={s}/>
                </div>
                <div  className={"v-line"}/>
                <div className={"s-col"} id={"first-row"}>
                    <h6>Weaknesses</h6>
                    <Bullets l={w}/>
                </div>
            </div>

            <div  className={"h-line"}/>

            {/*Second Row*/}
            <div className={"s-row"}>
                <div className={"s-col"} id={"second-row"}>
                    <h6>Opportunities</h6>
                    <Bullets l={o}/>
                </div>
                <div  className={"v-line"}/>
                <div className={"s-col"} id={"second-row"}>
                    <h6>Threats</h6>
                    <Bullets l={t}/>
                </div>
            </div>
        </div>
    )
}

export default Swot