import React from 'react'
import './rts.css'
import {Header, Overview, Goals, Persona, ZoomImg, UserScenario, OtherProjects} from "../../../components/index";
import { rts_preview, competitor, prsn_j, prsn_k, prsn_h, stkhldr, flow, ds, wf,scenario0, scenario1, scenario2, scenario3, scenario4} from "../../../assets/project_pngs/rts";
import {comp0, comp1, comp2, comp3} from "../../../assets/project_pngs/solargators";

// colors = [primary, secondary, text]
let colors = ["#86B6FF", "rgba(134, 182, 255, .28)", "#005FF0"];

const RTS = () => {
    return (
        <div className={"content-container"}>
            <Header pageTitle={"Gainesville Regional Transport Service (RTS) Bus App"}/>
            <div className={"content"}>

                <Overview
                    summary={"This was a final project for a UI/UX course in which my team and I redesigned the Gainesville RTS bus app."}
                    categories={["UI Design", "UX Design", "UX Research"]}
                    colors={colors}
                    role={["UI/UX Research, Design"]}
                    duration={["Mar - May 2023"]}
                    type={"App Redesign"}
                    team={"Team: 3 UX Designers"}
                    tools={["Figma", "Adobe Photoshop"]}
                    pic={rts_preview}
                />

                {/* ----BACKGROUND---- */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Project Background</h2>
                            <p>Gainesville Regional Transit System (RTS) is the local area bus system that serves
                                the Gainesville, FL area, including the University of Florida (UF) campus.
                                Many UF students rely on RTS buses to commute to campus and around Gainesville.
                                Determining a bus route that aligns with their busy and unpredictable schedules can be
                                stressful and overwhelming, especially for first-time riders.
                                RTS has a bus app, however, it is confusing and unintuitive for users to navigate.
                            </p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>My Role</h3>
                            <p>Throughout this project, my team and I worked collaboratively across research, design,
                                and user testing. I was responsible for establishing
                                <span className={"span-accent"}> project goals </span>
                                and
                                <span className={"span-accent"}> design requirements</span>
                                , helping create
                                <span className={"span-accent"}> user personas</span>
                                , designing
                                <span className={"span-accent"}> wireframes</span>
                                , and developing the final
                                <span className={"span-accent"}> prototype</span>.
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----RESEARCH---- */}
                <div className={"content-section"}>
                    <h4>Research</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Interviews</h3>
                            <p>Interviewing 4 students at UF, we identified user pain points with the existing RTS Bus app as</p>
                            <ul>
                                <li>Difficulty navigating app to find route information</li>
                                <li>Unreliability of real-time bus location</li>
                                <li>Visual clutter and lack of appeal</li>
                            </ul>
                        </div>

                        <div className={"content-sub-block"}>
                            <h2>Market Analysis</h2>
                            <div className={"content-sub-sub-block"}>
                                <h3>Stakeholder Map</h3>
                                <div id={"stakeholder-container"}>
                                    <img src={stkhldr} alt={"stakeholders"} className={"rogue_img"} style={{width: "75%"}}/>
                                </div>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Competitor Analysis</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>
                                    <h6>Google Maps</h6>
                                    <ul>
                                        <li>Most popular alternative for checking bus schedules due to ease of navigation</li>
                                        <li>Potential for route inaccuracies</li>
                                    </ul>
                                </p>
                                <p>
                                    <h6>Lyft & Uber</h6>
                                    <ul>
                                        <li>Convenient transportation</li>
                                        <li>Costly to use on a daily basis</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----DEFINE---- */}
                <div className={"content-section"}>
                    <h4>Define</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Personas</h3>
                            <div className={"content-sub-sub-block"}>
                                <Persona pic={prsn_k}
                                         name={"Kevin"}
                                         age_gen={"20 years old | Male"}
                                         position={"Full Time Student"}
                                         company={"University of Florida"}
                                         location={"Gainesville, FL"}
                                         bio={"Kevin is an international student at UF and has lived in Gainesville for one year. He doesn't speak fluent English or own a car, but likes to do athletic sports and hangout with friends."}
                                         goals_list={["Conveniently go grocery shopping", "Easily go around town with friends"]}
                                         frustations={["RTS app not user friendly for non-native speakers", "Does not know how to navigate town"]}
                                />
                                <div className={"separating_line"}/>
                                <Persona pic={prsn_j}
                                         name={"Jane"}
                                         age_gen={"19 years old | Female"}
                                         position={"Full Time Student"}
                                         company={"University of Florida"}
                                         location={"Gainesville, FL"}
                                         bio={"Jane is a Junior BADAS Major. She lives downtown and sometimes spends all day on campus when she has after-school club events."}
                                         goals_list={["Get to campus quickly", "Commute safely"]}
                                         frustations={["Off-campus student parking is too far from classes", "Parking decals are expensive", "Uncomfortable walking home in the dark"]}
                                />
                                {/*<div className={"separating_line"}/>*/}
                                {/*<Persona pic={prsn_h}*/}
                                {/*         name={"Harold"}*/}
                                {/*         age_gen={"62 years old  |  Male"}*/}
                                {/*         position={"Retail store employee"}*/}
                                {/*         company={"Roses Express"}*/}
                                {/*         location={"Gainesville, FL"}*/}
                                {/*         bio={"Harold has been working at the local retail store, Roses Express, for almost 10 years. He is close to retiring and generally lives a nice balanced lifestyle between going to work, spending time with his family, and visiting neighbors."}*/}
                                {/*         goals_list={["Get to work on time by bus", "Visit friends and families around town easily"]}*/}
                                {/*         frustations={["Unsure of how to navigate RTS bus app", "Construction and traffic delays interfere with his commute"]}*/}
                                {/*/>*/}
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>User Scenario</h3>
                            <UserScenario
                                p0={"Meet Kevin! He is an international student starting his second year at UF and has just moved into an off-campus apartment building in Gainesville."}
                                p1={"Kevin doesn’t own a car and his new apartment isn’t within walking distance to the grocery store or campus. He’s used carpooling and ride-sharing apps before but knows he can’t afford them for everyday commuting."}
                                p2={"The semester starts in a few days and Kevin needs a reliable mode of transportation to get to his classes on time. He has seen buses on campus and around Gainesville, but has never used public transit."}
                                p3={"After a quick search, Kevin finds the RTS bus app and wants to find out what bus can take him from his apartment to UF campus for his 10:40am class on Mondays and Wednesdays."}
                                i0={scenario0}
                                i1={scenario1}
                                i2={scenario2}
                                i3={scenario3}
                            />
                        </div>

                        <div className={"content-sub-block"}>
                            <h2>Project Goals</h2>
                            <Goals
                                g={[["Improve app navigation", " for new riders & app users by implementing a more recognizable user interface employing Google Material Design"],
                                    ["Improve clarity and presentation of route information", " with identifiable iconography and step-by-step route progression"]]}
                            />
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Design Requirements</h3>
                            <p>Based on our research findings, I outlined the necessary functionalities to incorporate in our app redesign.</p>
                            <ol>
                                <li>Real-time map with user and bus locations</li>
                                <li>Populate routes based on start & end destinations</li>
                                <li>Ability to favorite routes</li>
                                <li>Ability to set a “Home” location</li>
                                <li>Display bus stop’s name and number</li>
                            </ol>
                        </div>
                    </div>
                </div>

                {/* ----IDEATE---- */}
                <div className={"content-section"}>
                    <h4>Ideate</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Flow</h3>
                            <div className={"content-sub-sub-block"}>
                                <img src={flow} alt={"ds"} className={"rogue_img"}/>
                            </div>
                        </div>



                        <div className={"content-sub-block"}>
                            <h3>Wireframe</h3>
                            <p>From the user flow chart, we decided on 3 main tabs:</p>
                            <p><span className={"span-accent"}>Real-time Map</span>
                                : Filter buses by ones going Home, on a favorited route, or to a specific stop
                            </p>

                            <p><span className={"span-accent"}>Search</span>
                                : Find bus routes between specific locations at a select time
                            </p>

                            <p><span className={"span-accent"}>Settings</span>
                                : Manage their Home location, favorited routes and profile
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----DESIGN---- */}
                <div className={"content-section"}>
                    <h4>Design</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Style Guide</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>The existing RTS bus app uses Google Maps’ API to displaying bus routes and locations. I created a style guide using Google Material Design to improve app navigation with recognizable interface features users may already be familiar with.</p>
                                <img src={ds} alt={"ds"} className={"rogue_img"}/>
                            </div>

                        </div>

                        <div className={"content-sub-block"}>
                            <h3>High Fidelity Mockup</h3>
                            <p><span className={"span-accent"}>
                                <a href={"/contact"}>Contact me </a></span>
                                to request access to the comprehensive Figma design.
                            </p>
                            <img src={wf} alt={"wireframe"} className={"rogue_img"}/>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Preliminary Testing</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>To gather more insightful feedback from potential users, our team conducted a preliminary user testing using the first iteration of our prototype. Here is some feedback we received:</p>
                                <p>
                                    <h6>Participant A:</h6>
                                    <ul>
                                        <li>User reported app to be overall intuitive to navigate</li>
                                        <li>No major pain points reported in the app navigating</li>
                                        <li>Suggested to add more colors and icons for distinction</li>
                                        <li>Suggested to get rid of the individual profile section because they did not see a purpose or use to it other than the part of changing one’s home location or favorites</li>
                                    </ul>
                                </p>
                                <p>
                                    <h6>Participant B:</h6>
                                    <ul>
                                        <li>Overall found the wireframes clear and easy to navigate</li>
                                        <li>Suggested to have pop-up notifications when watching a route in real-time</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Prototype</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Explore the comprehensive
                                    <span className={"span-accent"}> Figma design.</span>
                                </p>
                                {/* FIX ME: VIDEO DEMO */}
                                <div className={"frame-wrap-mobile"}>
                                    <iframe title="rts-figma" id={"frame-scale-mobile"} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FZgz80jDCd6h8rfPdmpX7XI%2FRTS-BUS-Wireframes%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D6-4%26viewport%3D-33%252C453%252C0.23%26t%3DuUEalERv85hHa5a9-1%26scaling%3Dscale-down%26starting-point-node-id%3D240%253A3461%26mode%3Ddesign"
                                            allowFullScreen={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----TESTING---- */}
                <div className={"content-section"}>
                    <h4>Testing</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Testing</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>
                                    <h6>Participant A</h6>
                                    <ul>
                                        <li>Asked about interface "showing warning regarding construction/road closure/event operation/sport games"</li>
                                        <li>Interface for login/signup needed</li>
                                        <li>Adhere color to the gator color scheme more</li>
                                    </ul>
                                </p>
                                <p>
                                    <h6>Participant B</h6>
                                    <ul>
                                        <li>Include option of “inbound” or “outbound” for the bus route, would be easier for user</li>
                                        <li>Overall interface is logical, just need to polish on small details</li>
                                    </ul>
                                </p>
                                <p>
                                    <h6>Participant C</h6>
                                    <ul>
                                        <li>“App functions really well and I can navigate it very intuitively. Wish it was more visually appealing though with colors.”</li>
                                        <li>Wants functional filtering by favorites and home feature</li>
                                        <li>Perhaps include a moveable map.</li>
                                        <li>Confused on “Home filter” button</li>
                                    </ul>
                                </p>
                                <p>
                                    <h6>Participant D</h6>
                                    <ul>
                                        <li>Should have a stops shown real time map and show which bus routes go to that bus stop</li>
                                        <li>Really likes the newer design but wishes it was less grey</li>
                                        <li>Confused on purpose of Profile section</li>
                                        <li>Would like to see a “Recent/Previous Routes” to avoid re-searching for routes.</li>
                                        <li>Would prefer to use this version over the current RTS app</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h2>Overall Evaluation</h2>
                            <div className={"content-sub-sub-block"}>
                                <p>Our redesigned RTS bus app was well received by our peers, professor, and interviewees.
                                    However, we still see some areas of improvement in the information delivery and overall
                                    design of the app to make it look and feel more uniquely UF and Gainesville-based. We
                                    were determined to make this app intuitive to use by pure design for all kinds of users.
                                    Given more time, we are confident that continuing this project for another semester would
                                    allow us to successfully achieve this goal.
                                </p>
                                <p>The RTS bus app has a lot of room for growth and can become a greatly convenient and
                                    reliable transportation option for many residents of Gainesville. With improvements to
                                    the app, RTS has the potential to increase its ridership across all residents of Gainesville.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OtherProjects proj1={"sg"} proj2={"myeo"}/>
        </div>
    )
}

export default RTS