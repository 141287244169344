import React from 'react'
import './solargators.css'
import {Header, Overview, Swot, Goals, Persona, OtherProjects, Highlights} from "../../../components/index";
import { old_website, comp0, comp1, comp2, comp3, persona1, old_ia, new_ia, wf,
        style_guide, mockup, sg_preview, prototype, home_rationale, sponsorship_rationale } from "../../../assets/project_pngs/solargators/index";

// colors = [primary, secondary, text]
let colors = ["#FFAD73",  "rgba(255, 132, 45, .15)",  "#E86000"];

const SolarGators = () => {
    return (
        <div className={"content-container"}>
            <Header pageTitle={"Solar Gators Website"}/>
            <div className={"content"}>

                <Overview
                    summary={"Website redesign for the Solar Gators engineering design team at the University of Florida."}
                    categories={["UX Research", "UI Design", "Web Development"]}
                    colors={colors}
                    role={["UI/UX Research, Design & Development"]}
                    duration={["August 2022 - May 2024"]}
                    type={"Website Design & Development"}
                    team={"Team: Solo"}
                    tools={["Figma", "React.js", "HTML + CSS", "Adobe Photoshop"]}
                    pic={""}
                />

                <Highlights
                    problem={["Outdated website deterred new members and sponsors due to it looking inactive and lacking information and visual appeal"]}
                    goals={["Attract new members and sponsors with a modern, informative website"]}
                    actions={["Integrated relevant information across all pages",
                        "Streamlined onboarding for sponsorship and membership",
                        "Modernized UI by incorporating more photos, color and dynamic UI"]}
                    outcomes={["2x annual donation revenue", "20% increase in new membership"]}
                    />

                {/*Jump to Final Prototype Button*/}
                <div className={"center-button"} >
                    <a href={"/solar-gators#prototype"} rel={"noreferrer"} >
                        <button>
                            <h6>Jump to Final Prototype</h6>
                            <img id={"arrow-down-icon"} alt={"arrow-down-icon"}/>
                        </button>
                    </a>
                </div>

                <div className={"image-container"}>
                    <img src={sg_preview} alt={"solar gators website cover img"}/>
                </div>

                {/* ----BACKGROUND---- */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Project Background</h2>
                            <p>Solar Gators is a competitive solar endurance racing team at the University of Florida. Their previous website was neglected for a few years, lacking information and visual appeal. Leadership wanted to improve their website to attract new members and sponsors.</p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Old Website</h3>
                            <img src={old_website} alt={"old website screenshot"} className={"rogue_img"}/>
                        </div>

                        <div className={"content-sub-block"}>
                            <div className={"content-sub-block"}>
                                <h3>Challenge</h3>
                                <p>How to improve visible online presence so Solar Gators looks like an active student organization for new members and sponsors to join?</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----RESEARCH---- */}
                <div className={"content-section"}>
                    <h4>Research</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>User Research & Analysis</h2>
                            <p>Before diving into this project, I spoke individually with members of solar gators to gain insight into their frustrations with the website. I found that members hardly used the website due to 2 key issues:
                                <ul>
                                    <li>Dislike of the visual design</li>
                                    <li>Lack of current and event information</li>
                                </ul>
                            </p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>SWOT Analysis</h3>
                            <Swot   s={["Included basic information about sponsorship, team leads, and history"]}
                                    w={["Looks inactive to potential new members and sponsors"]}
                                    o={["Increase membership and sponsorship by providing more information and visually appealing"]}
                                    t={["Required a 3-click process before sponsors could donate", "No information for new members"]}
                            />
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Competitor Analysis</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>To gain a broader perspective, I compared the Solar Gators website with 4 other solar racing teams.</p>
                                <div className={"row-space-between"} id={"sg-competitor-logos"}>
                                    <img className={"competitor-logo"} src={comp1} alt={"umich"}/>
                                    <img className={"competitor-logo"} src={comp3} alt={"mit"}/>
                                    <img className={"competitor-logo"} src={comp0} alt={"top-dutch"}/>
                                    <img className={"competitor-logo"} src={comp2} alt={"stanford"}/>
                                </div>
                                <div id={"sg-competitor-stats"}>
                                    <div className={"multi-column-row"}>
                                        <div className={"multi-column-column"}>
                                            <h2>75%</h2>
                                            <ul>
                                                <li>Include statistics about the club</li>
                                                <li>Incorporate 2 bold accent colors associated with their university</li>
                                            </ul>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>100%</h2>
                                            <ul>
                                                <li>Have a page dedicated to car information</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Findings</h3>
                            <p>The primary <span className={"span-accent"}>user pain points</span> were
                                <ul>
                                    <li><span className={"span-accent"}> Finding information</span> about the club and how to join</li>
                                    <li>The<span className={"span-accent"}> club looking inactive</span> due to the lack of appeal of outdated UI</li>
                                </ul>
                                These issues deterred current members and likely potential new members and sponsors too.
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----DEFINE---- */}
                <div className={"content-section"}>
                    <h4>Define</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Goals</h2>
                            <p>
                                From the research and feedback, I created goals to achieve Solar Gators' overall objectives:
                                <Goals
                                    g={[["Improve member engagement", " by maintaining appearance of ongoing activities via incorporating more current information about club"],
                                        ["Promote interest", " with improved visual appearance by implementing a modernized style guide"],
                                        ["Increase sponsorship", " via a streamlined process between landing page and donation site"]]}
                                />
                            </p>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>User Personas</h3>
                            <Persona pic={persona1}
                                     name={"Jack Thompson"}
                                     age_gen={"38 years old  |  Male"}
                                     position={"Corporate Social Responsibility Manager"}
                                     company={"GreenTech Solutions"}
                                     location={"San Francisco, CA"}
                                     bio={"Responsible for identifying strategic partnerships that promote clean energy."}
                                     motiv={"Looking to enhance GreenTech’s reputation as a leader in sustainable technology by supporting clean energy projects."}
                                     goals={"To gauge potential return on investment by finding information about organization’s performance and sponsor benefits."}
                            />
                        </div>
                    </div>
                </div>

                {/* ----IDEATE---- */}
                <div className={"content-section"}>
                    <h4>Ideate</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Information Architecture</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>The information architecture was remapped to
                                    <ul>
                                        <li>Accommodate additional information and content, namely the <span className={"span-accent"}>addition of a ‘Cars’ page</span></li>
                                        <li>Eliminate inactive impression by <span className={"span-accent"}>removing the "Events" page</span> due to its infrequent updates and lack of use by current members</li>
                                        <li><span className={"span-accent"}>Streamline sponsorship</span> with a 2-click process, rather than 3</li>
                                        <li>Enhance page engagement by designing pages to <span className={"span-accent"}>organically lead traffic</span>, instead of solely relying on the navigation bar</li>
                                    </ul>
                                </p>
                                <h5>Old IA</h5>
                                    <img src={old_ia} alt={"old-IA"} className={"rogue_img"}/>
                                <h5>New IA</h5>
                                    <img src={new_ia} alt={"new-IA"} className={"rogue_img"}/>
                            </div>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>Low-Fi Wireframes</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>After defining the information architecture, I created low-fidelity wireframes to experiment with the best ways to show information and images in a clear and engaging way.</p>
                                <img src={wf} alt={"UX wireframe"} className={"rogue_img"}/>
                            </div>
                        </div>


                    </div>
                </div>

                {/* ----DESIGN---- */}
                <div className={"content-section"}>
                    <h4>Design</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Design Decisions</h3>
                            <div className={"content-sub-sub-block"}>
                                {/*<p>Here's a closer look at the design decisions I made for the two most important pages of the website: home and sponsorship.</p>*/}
                                <p> </p>
                                <h5>Home Page</h5>
                                <p>My goal was to create a concise overview of Solar Gators that organically engages users and encourages them to discover relevant information on other pages of the website.</p>
                                <img src={home_rationale} alt={"UI mockup"} className={"rogue_img"} id={"sg_home_rational"}/>
                            </div>
                            <div className={"content-sub-sub-block"}>
                                <p> </p>
                                <h5>Sponsorship Page</h5>
                                <p>I aimed to convert website visitors into Solar Gators sponsors through clear sponsorship and donation information. Here's how I implemented this strategy through my design decisions...</p>
                                <img src={sponsorship_rationale} alt={"UI mockup"} className={"rogue_img"} id={"sg_sponsorship_rational"}/>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>High-Fi MockUp</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Explore different iterations of the
                                    <span className={"span-accent"}>
                                        <a href={"https://www.figma.com/proto/wC3PdeL4jk0jZcQuhUZpeV/Solar-Gators-Website?page-id=0%3A1&node-id=406-1162&viewport=597%2C429%2C0.06&t=Nyz9s0aR6h4jVeK0-1&scaling=min-zoom&starting-point-node-id=406%3A1162&show-proto-sidebar=1"} target="_blank" rel="noreferrer"> Figma prototype.</a>
                                    </span>
                                </p>
                                <img src={mockup} alt={"UI mockup"} className={"rogue_img"}/>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Style Guide</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>I created a style guide that stayed true to the recognizable UF brand. I made slight adjustments to the well-known colors to ensure the text, buttons, and background have good contrast for easy readability.</p>
                                <img src={style_guide} alt={"style guide"} className={"rogue_img"}/>
                            </div>
                        </div>

                        <div className={"content-sub-block"} id={"prototype"}>
                            <h3>High-Fi Prototype</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Explore different iterations of the
                                    <span className={"span-accent"}>
                                        <a href={"https://www.figma.com/proto/wC3PdeL4jk0jZcQuhUZpeV/Solar-Gators-Website?page-id=0%3A1&node-id=406-1162&viewport=597%2C429%2C0.06&t=Nyz9s0aR6h4jVeK0-1&scaling=min-zoom&starting-point-node-id=406%3A1162&show-proto-sidebar=1"} target="_blank" rel="noreferrer"> Figma prototype.</a>
                                    </span>
                                </p>
                                <div className={"prototype_container"}>
                                    <video controls autoPlay loop muted className={"desktop_prototype"}>
                                        <source src={prototype} type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                {/* ----DEVELOPMENT---- */}
                <div className={"content-section"}>
                    <h4>Development</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>From Figma to React</h3>
                            <p>I developed the designs from Figma using
                                <span className={"span-accent"}> React.js</span>,
                                <span className={"span-accent"}> HTML </span>and
                                <span className={"span-accent"}>  CSS</span> beginning in January 2023.
                                The redesign launched in May 2023. Some key areas of improvement to the code base include
                                ensuring responsiveness for mobile and laptop screen sizes, maximizing component reuse,
                                emphasizing code readability, and optimizing file structure.
                            </p>
                        </div>
                        <div className={"content-sub-block"}>
                            <a href={"https://www.ufsolargators.org"} target={"_blank"}  rel={"noreferrer"} >
                                <button id={"sg-website-button"}>
                                    <h6>Solar Gators Website</h6>
                                    <img alt={"external-link-icon"} id={"arrow-external-icon"}/>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                {/* ----TESTING---- */}
                <div className={"content-section"}>
                    <h4>Testing</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>User Feedback</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Feedback from a survey of 20+ Solar Gators members showed</p>
                                <div id={"sg-feedback"}>
                                    <div className={"multi-column-row"}>
                                        <div className={"multi-column-column"}>
                                            <h2>81%</h2>
                                            <p>Felt satisfied with the amount of information on the website</p>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>75%</h2>
                                            <p>Of new members visited the website to learn about the club</p>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>33%</h2>
                                            <p>Of new members joined via the website</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>Business Impact</h3>
                            <div className={"content-sub-sub-block"}>
                                <div id={"sg-results"}>
                                    <div className={"multi-column-row"}>
                                        <div className={"multi-column-column"}>
                                            <h2>2x Donations</h2>
                                            <p>Annual donations revenue doubled from 2022 to 2023</p>
                                        </div>
                                        <div className={"multi-column-column"}>
                                            <h2>20% Increase</h2>
                                            <p>In new member recruitment between Spring and Fall 2023</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----REFLECTION---- */}
                <div className={"content-section"}>
                    <h4>Reflection</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>What I Learnt</h3>
                            <p>My primary objective was to improve the Solar Gators website to help attract new members
                                and sponsors to the club. I focused on incorporating more information about the club
                                and presenting it in a visually appealing way to improve the overall user experience.
                                I learnt that creating design goals that focused on achievable improvements was an
                                important part of showing the success of my design strategies. Furthermore, this project
                                reinforced in me the iterative nature of the UX design process.</p>
                        </div>
                        <div className={"content-sub-block"}>

                        </div>
                    </div>
                </div>
            </div>

            <OtherProjects proj1={"rts"} proj2={"myeo"}/>
        </div>

    )
}

export default SolarGators