import React from 'react'
import './overview.css'
import {Tags} from "../index";

const Overview = ({summary, categories, colors, role, duration, type, team, tools, pic}) => {

    const Categories = () => (
        <Tags categories={categories} colors={colors}/>
    )

    const Info = ({title, body}) => (
        <>
            <h3>{title}</h3>
            <div className={"tri-section-content"}>
                { body[0] ? <body>{body[0]}</body> : ""}
                { body[1] ? <body>{body[1]}</body> : ""}
                { body[2] ? <body>{body[2]}</body> : ""}
                { body[3] ? <body>{body[3]}</body> : ""}
                { body[4] ? <body>{body[4]}</body> : ""}
            </div>
        </>
    )

    const TriSection = ({title, body}) => (
        <div className={"tri-section"}>
            <Info title={title} body={body}/>
        </div>
    )

    const Preview = () => {
        if (pic !== "") {
            return (
                <div className={"image-container"}>
                    <img src={pic} alt={"overview img"}/>
                </div>
            )
        }
    }

    return (
        <div className={"overview-container"}>
            <h6 id={"summary"}>{summary}</h6>
            <Categories/>
            <div className={"tri-section-container"}>
                <TriSection title={"My Role"} body={role}/>
                <div className={"line"}/>
                <TriSection title={"Project Context"} body={[duration, type, team]}/>
                <div className={"line"}/>
                <TriSection title={"Tools"} body={tools}/>
            </div>
            <Preview/>
        </div>
    )
}

export default Overview