import React from 'react'
import './projects.css'
import { Header, ProjectPreview } from "../../components/index";
import {myeo_preview} from '../../assets/project_pngs/myeo/index';
import {rts_preview} from "../../assets/project_pngs/rts/index";
import {sg_preview} from "../../assets/project_pngs/solargators/index";
import PPLandscape from "../../components/projectpreview-landscape/PPLandscape";

//Unused - 2/10/24

const Projects = () => {
    return (
        <>
            <Header pageTitle={"Projects"}/>
            <div className={"project-previews-container"}>
                <PPLandscape
                    role={"UI/UX Designer & Developer"}
                    title={"Solar Gators Website"}
                    categories={["UI/UX Design", "UX Research", "Web Development"]}
                    description={"Increased interest and sponsorship by redesigning Solar Gator’s website to incorporate more information and streamline sponsorship with a modernized style guide"}
                    pic={sg_preview}
                    route={"./solar-gators"}
                    align={"default"}
                    colors={["#0067C7", "rgba(0, 103, 199, .25)"]}
                />

                <PPLandscape
                    role={"UI/UX Designer"}
                    title={"Gainesville Regional Transport Service Bus App"}
                    categories={["UI/UX Design", "UX Research"]}
                    description={"Redesigned the Gainesville RTS bus app using Google Material Design conventions to " +
                        "improve the on-boarding experience for new app users " +
                        "and presentation of route information"}
                    pic={rts_preview}
                    route={"./rts"}
                    align={"flip"}
                    colors={["#86B6FF", "rgba(134, 182, 255, .25)"]}
                />

                {/*<ProjectPreview title={"Doggie Daycare Dating Game"}*/}
                {/*                categories={"Game Dev | UI Design"}*/}
                {/*                pic={p1_img}*/}
                {/*                route={"./doggie-daycare"}/>*/}
                <PPLandscape
                    role={"UX Designer"}
                    title={"My Essential Oils iOS App"}
                    categories={["UX Design"]}
                    description={"Designed a recipe search filter feature for the MyEO Young Living Essential Oils mobile iOS app"}
                    pic={myeo_preview}
                    route={"./my-eo"}
                    align={"default"}
                    colors={["#63d9b9", "rgba(131, 225, 199, .25)"]}
                />
            </div>
        </>

    )
}

export default Projects