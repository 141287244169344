import React from 'react'
import Header from "../../components/header/Header";


const NotFound = () => {
    return (
        <Header pageTitle={"Oops 404 Not Found :/"}/>
    )
}

export default NotFound