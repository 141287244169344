import React from 'react'
import './goals.css'

const Goals = ({g}) => {
    let Goal = ({num, emphasis, text}) => {
        return (
            <div className={"g-row"}>
                <h2>{num}</h2>
                <body><span className={"span-accent"}>{emphasis}</span>{text}</body>
            </div>
        )
    }

    return (
        <div>
            { g[0] ? <Goal num={"1"} emphasis={g[0][0]} text={g[0][1]}/> : "" }
            { g[1] ? <Goal num={"2"} emphasis={g[1][0]} text={g[1][1]}/> : "" }
            { g[2] ? <Goal num={"3"} emphasis={g[2][0]} text={g[2][1]}/> : "" }
            { g[3] ? <Goal num={"4"} emphasis={g[3][0]} text={g[3][1]}/> : "" }
            { g[4] ? <Goal num={"5"} emphasis={g[4][0]} text={g[4][1]}/> : "" }
        </div>
    )
}

export default Goals