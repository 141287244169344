import React from 'react'
import './contact.css'
import { Header, ContactForm } from "../../components/index";


const Contact = () => {
    return (
        <div className={"content-container"} id={"contact-container"}>
            <Header pageTitle={"Contact"}/>
            <ContactForm/>
        </div>
    )
}

export default Contact