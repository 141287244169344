import React from 'react'
import './projectpreview.css'
import { Tags } from "../index";

const ProjectPreview = ({role, title, categories, description, pic, route, align, colors}) => {
    let Content = () => {
        return (
            <div className={"op-preview"}>

                <Image/>

                <div>
                    <h6>{role}</h6>
                    <a href={route}><h3>{title}</h3></a>
                </div>

                <Tags categories={categories} colors={colors}/>

            </div>
        )
    }

    let Image = () => {
        return (
            <a href={route}>
                <div className={"op-preview-img_container"} style={{background: colors[0]}}>
                    <img src={pic} alt={"project mockup img"}/>
                </div>
            </a>
        )
    }

    return (
        <div className={"preview"}>
            <Content/>
        </div>
    )
}

export default ProjectPreview