import React from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";

import { Navbar, Footer } from './components';
import Home from './pages/home/Home'
import Projects from './pages/projects/Projects'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import Resume from './pages/resume/Resume'
import NotFound from './pages/notFound/NotFound'

import NeuroTalent from "./pages/project_pages/neurotalent/NeuroTalent";
import SolarGators from "./pages/project_pages/solargators/SolarGators";
import EO from "./pages/project_pages/eo/EO";
import RTS from "./pages/project_pages/rts/RTS";

import './App.css'


class App extends React.Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Navbar/>
                    <Routes>
                        {/* Navbar Pages  />*/}
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/projects" element={<Projects/>}/>
                        <Route path="/resume" element={<Resume/>}/>
                        <Route path="/contact" element={<Contact/>}/>

                        {/* Project Pages */}
                        <Route path="/solar-gators" element={<SolarGators/>}/>
                        <Route path="/my-eo" element={<EO/>}/>
                        <Route path="/rts" element={<RTS/>}/>
                        <Route path="/neuro-talent" element={<NeuroTalent/>}/>

                        {/* 404 page not found */}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </div>
        )
    }
}

export default App