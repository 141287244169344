import React from 'react'
import './persona.css'

const Persona = ({pic, name, age_gen, position, company, location, bio, motiv, goals, goals_list, frustations}) => {
    let Motivations = () => {
        if (motiv) {
            return(
                <div className={"p-row"}>
                    <h6>Motivations</h6>
                    <p>{motiv}</p>
                </div>
            )
        }
        else { return (<></>) }
    }

    let Goals = () => {
        if (goals) {
            return (
                <div className={"p-row"}>
                    <h6>Goals</h6>
                    <p>{goals}</p>
                </div>
            )
        }
        else if (goals_list[0]) {
            return (
                <div className={"p-row"}>
                    <h6>Goals</h6>
                    <p><ul>
                        { goals_list[0] ? <li>{goals_list[0]}</li> : "" }
                        { goals_list[1] ? <li>{goals_list[1]}</li> : "" }
                        { goals_list[2] ? <li>{goals_list[2]}</li> : "" }
                        { goals_list[3] ? <li>{goals_list[3]}</li> : "" }
                    </ul></p>
                </div>
            )
        }
        else { return (<></>) }
    }

    let Frustrations = () => {
        if (frustations) {
            return (
                <div className={"p-row"}>
                    <h6>Frustrations</h6>
                    <p><ul>
                            {frustations[0] ? <li>{frustations[0]}</li> : ""}
                            {frustations[1] ? <li>{frustations[1]}</li> : ""}
                            {frustations[2] ? <li>{frustations[2]}</li> : ""}
                            {frustations[3] ? <li>{frustations[3]}</li> : ""}
                        </ul></p>
                </div>
            )
        }
        else { return (<></>) }
    }

    return (
        <div className={"persona"}>
            <div className={"p-row"} id={"ov"}>
                <div className={"profile-img-container"}>
                    <img src={pic} alt={"profile"}/>
                </div>
                <div className={"p-overview"}>
                    <h3>{name}</h3>
                    <p id={"subtitle"}>{age_gen}</p>
                    <p id={"position_company"}><span id={"position"}>{position}</span> at {company}</p>
                    <p id={"location"}>{location}</p>
                </div>
            </div>
            <div className={"p-row"}>
                <h6>Biography</h6>
                <p>{bio}</p>
            </div>

            <Motivations/>

            <Goals/>

            <Frustrations/>
        </div>
    )
}

export default Persona