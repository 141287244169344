import React from 'react'
import './eo.css'
import {Header, OtherProjects, Overview, Persona, ZoomImg} from "../../../components/index";
import { cover, persona, wf, mockup, mockupFull, prototype} from "../../../assets/project_pngs/myeo/index";
import {old_ia} from "../../../assets/project_pngs/solargators";

//colors = [primary, secondary, text]
let colors = ["#63d9b9",  "rgba(131, 225, 199, .28)", "#007A59"];

const EO = () => {
    return (
        <div className={"content-container"}>
            <Header pageTitle={"My Essential Oils iOS App"}/>
            <div className={"content"}>

                <Overview
                    summary={"A new app feature for the MyEO Young Living Essential Oils mobile iOS app."}
                    categories={["UX Design"]}
                    colors={colors}
                    role={["UX Designer"]}
                    duration={["Aug - Sept 2022"]}
                    type={"Mobile iOS App Feature"}
                    team={"Team: Developer"}
                    tools={["Figma"]}
                    pic={cover}
                />
                {/* ----BACKGROUND---- */}
                <div className={"content-section"}>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Project Background</h2>
                            <p>The Young Living Essential Oils app serves as a comprehensive reference guide, equipping users with valuable insights about essential oils and offering an array of recipes for users to craft unique oil blends that promote health and wellness.</p>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>My Role</h3>
                            <p>I was recruited as the<span className={"span-accent"}> UX Designer </span>for the development of this app feature. I worked closely with the front-end developers to ensure seamless integration of my designs into their pre-existing framework.</p>
                        </div>
                    </div>
                </div>

                {/* ----DEFINE---- */}
                <div className={"content-section"}>
                <h4>Define</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h2>Goals</h2>
                            <p>The developer sought to enhance the app by introducing a refined search feature. This would allow for more precise and efficient navigation, enabling users to quickly pinpoint relevant content and ensuring a smoother, more gratifying experience using the app.</p>
                        </div>
                        <div className={"content-sub-block"}>
                            <h3>Product Requirements</h3>
                            <p>The PRD outlined the concept for a new feature to allow users to search for recipes filter by:
                                <ul>
                                    <li>Search Entry</li>
                                    <li>Oils in user's "My Inventory"</li>
                                    <li>A custom filter</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                {/* ----IDEATE---- */}
                <div className={"content-section"}>
                    <h4>Ideate</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>Wireframe</h3>
                            <p>I brainstormed a few preliminary concepts for structuring the filter feature, and upon
                                careful evaluation, Option 1 emerged as the better choice. This approach effectively
                                presents users with the three distinct search methods — query, inventory, or custom —
                                right within the search tab, while also neatly housing the custom filter options on a
                                separate page.</p>
                            <img src={wf} alt={"sketch-wireframe"} className={"rogue_img"}/>
                        </div>
                    </div>
                </div>

                <div className={"content-section"}>
                    <h4>Design</h4>
                    <div className={"content-blocks"}>
                        <div className={"content-sub-block"}>
                            <h3>High Fidelity Mockup</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Since I was integrating a new app feature into an existing framework without altering the interface, I adhered closely to the established design system of the app to ensure a seamless integration.</p>
                                <img src={mockup} alt={"initial-mockup"} className={"rogue_img"}/>
                                <p>I met with the developer to review the evolving feature's progress and verify that all desired functionalities were integrated within the design. I then created a complete mockup of all potential screens.</p>
                                <img src={mockupFull} alt={"final-mockup"} className={"rogue_img"}/>
                            </div>
                        </div>

                        <div className={"content-sub-block"}>
                            <h3>Prototype</h3>
                            <div className={"content-sub-sub-block"}>
                                <p>Request access to explore the comprehensive
                                    <span className={"span-accent"}><a href={"https://www.figma.com/file/wC3PdeL4jk0jZcQuhUZpeV/Solar-Gators-Website?type=design&node-id=0%3A1&mode=design&t=bgS1OtIuKP77WDEJ-1"} target="_blank" rel="noreferrer"> Figma design.</a></span>
                                </p>
                                <div className={"center-flex-container"}>
                                    <div className={"eo-video-container"}>
                                        <video src={prototype} id={"eo-prototype"} controls/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <OtherProjects proj1={"sg"} proj2={"rts"}/>
        </div>
    )
}

export default EO